/*
 *   Click on color change image
 */
$(".color-list-item").on('click', function (e) {

    var urlDomain = window.location.origin;
    let Id = $(this).data('product-id');
    let colorId = $(this).data('color-id');
    let element = $(this).closest('.product-content').find('.product-list-image img');
    let link = $(this).closest('.product-content').find('a');

    $(".product-content").find(".active").removeClass("active");
    $(this).toggleClass("active").next();
      
    
    $.ajax({
        url: urlDomain + "/api/products/getColorImage?id=" + Id,
        type: "GET",
        success: function (res) {
            
            element.attr('src', res);
            link.attr("href", function (i, href) {
                return link.data('url') + '?color=' + colorId;
            });
        },
        error: function (err) {},
    });
});


$('.filter-color-content .custom-control-label').on('click', function() {
    $(".filter-color-content").find(".active").removeClass("active");
    $(this).toggleClass("active").next();
});

/*
 *   Add product
 */
 
$(document).ready(function () {


    let owlTwo = $('.owl-carousel-two');

    let dynamicGalleryItems;
    const ex1 = document.getElementById('ex1');
    const ex2 = document.getElementById('ex2');

        
    if (ex1) {
        ex1.addEventListener('click', function () {
            var galleryImageSource = $('#ex1 .image-product').attr('src');
            const lguid = ex1.getAttribute('lg-uid');

            if (lguid) {
                window.lgData[lguid].destroy(true)
            }
            const indexGalleryImage = [...dynamicGalleryItems].filter(img => img.src == galleryImageSource)[0]?.index;

            lightGallery(ex1, {
                thumbnail: true,
                dynamic: true, 
                dynamicEl: [...dynamicGalleryItems], 
                index: indexGalleryImage
            });

        });
    } 
    
    if (ex2) { 
        ex2.addEventListener('click', function () {
            var galleryImageSource = $('#ex1 .image-product').attr('src');
            const lguid = ex2.getAttribute('lg-uid');

            if (lguid) {
                window.lgData[lguid].destroy(true)
            }
            const indexGalleryImage = [...dynamicGalleryItems].filter(img => img.src == galleryImageSource)[0]?.index;

            lightGallery(ex2, {
                thumbnail: true,
                dynamic: true, 
                dynamicEl: [...dynamicGalleryItems], 
                index: indexGalleryImage
            });

        });
    }

 
    setTimeout(function () {
        if (!$("input[name='boja']:checked").val()) {
            $('.filter-color-content').find('input[name=boja]:eq(0)').attr('checked', 'checked');
        } 
        $("input[type='radio'][name='boja']:checked").trigger('change');
    }, 0.1);

    var choosen_color = null;
    var choosen_size = null;
    var product = null;
    var buy_id = null;

    
    var productdata = $('.json').length ? JSON.parse($('.json').text()) : null;

    $("input[type='radio'][name='boja']").on('change', function () {
        if(!productdata) {
            return;
        }
        
        product = productdata.products[$(this).val()];
        product = product[Object.keys(product)[0]][0];

        choosen_color = $(this).val();
        // console.log(choosen_color);
        choosen_size = null; 
        setproductdata(product, choosen_color, choosen_size, "color");

        // set color hidden input
        $('#color').val(choosen_color);
        // load images for preview
        if (product.images.length < 4) {
            $('.thumbnails').thumbnailsGal();
        } else {
            owlTwo.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-two'
            });
        }
        $('.zoom').zoom();

        $('.share-icons .share').each(function(index, value){
            value.dataset.url = window.location.href;
        });
 
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?color=' + choosen_color;
        // console.log(newurl);
        window.history.pushState({ path: newurl }, '', newurl); 

        //end load images for preview

        return false;
    });

    function initDataForColor() {
        if(!productdata){
            return;
        }
        // console.log($('input[name="boja"]:checked').val());
        product = productdata.products[$('input[name="boja"]:eq(0)').val()];
        // console.log('Product', productdata.products);
        if (product) {
            product = product[Object.keys(product)[0]][0];
        }

        choosen_color = $('input[name="boja"]:checked').val();
        // console.log(choosen_color);
        choosen_size = null;

        setproductdata(product, choosen_color, choosen_size, "color");


        return false;
    }

    function setproductdata(product, choosen_color = null, choosen_size = null, type = null) {

        if (type != "first") {
            $(".thumbnails").text('');

            $('.owl-carousel-two .item').each((index, value) => {
                owlTwo.trigger('remove.owl.carousel', index);
            })
            if(product.images && product.images.length) {
                $.each(product.images, function (key, value) {
                    if (key == 0) {
                        $('.image-product').attr('src', value);
                    }
                    item = '<a href="' + value + '" class="product-margin"> <img src="' + value + '" alt="Thumbnail" class="thumb"> </a>';

                    owlTwo.owlCarousel('add', '<div class="item"> ' + item + ' </div>');

                });
            } 
            owlTwo.owlCarousel('update'); //Updatuje carousel
            owlTwo.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-two'
            });

            // console.log('zamena boje');

            mappingGalleryImages();
            
               

        }

        if (type != "size") {

            $('.tabela-podaci tbody').text('');
            $('#accordion-product-request').text('');
            $('.tabela-podaci-h').show();
            let collapse_index = 1;
            let show = 'show';
            $.each(productdata.products[choosen_color], function (key, value) {
                let tdList;
                // console.log(key); 
                const minPackage = JSON.parse(value[0].languages[0].translation.all_details).CommercialPackage;
                // console.log({minPackage});

                const quanVal = !value[0]?.quantity ? "disabled-quantity" : "";
                const inputDisabledAttr = !value[0]?.quantity ? "disabled" : "";
                let priceValue = Math.round(value[0].price * 100) / 100;
                let posaljiNaUpit = $('.upit-text').text();

                // console.log(posaljiNaUpit);

                let zaglavlje_tabele = '<th class="d-xs-none">'+ code +'</th><th>' + stock + '</th><th>' + arrival + '</th><th>'+ price +'</th><th>'+ quantity +'</th>';
                let sifra_velicine = value[0].real_id;
                tdList += '<td class="size d-xs-none">' + value[0].real_id + '</td>';
                tdList += '<td class="quantity quan' + key + '">' + value[0].quantity + '</td>';
                tdList += '<td class="arrivals arriv' + key + '">' + value[0].arrivals + '</td>';
                if(priceValue == '0') {
                    tdList += '<td class="price">' + posaljiNaUpit + '</td>';

                } else {
                    tdList += '<td class="price">Na upit</td>';
                    // tdList += '<td class="price">' + priceValue + '&nbsp;EUR / kom.</td>';
                }
                var urlDomain = window.location.origin; 
                if (value[0].incart) {
                    if ($(window).width() < 768) {
                        tdList += '<td  colspan="3">'+ productListStatus +'</td>';
                    } else {
                        tdList += '<td class="in-list"><a href="'+urlDomain+'/sr/cart" class="btn btn-sm btn-primary"><i class="p-shopping-cart mr-2"></i>'+productListStatus+'</a></td>';
                        $('.dugme-za-upit').attr("disabled", "disabled");
                    }
                } else {
                    if ($(window).width() < 768) {
                        $('.kolicina').remove();
                        tdList += '<td class="quantity-buy">' +
                            '<div class="input-counter">' +
                                '<i class="p-minus minus remove-package ' + quanVal + ' "></i>' +
                                    '<input name="' + value[0].real_id + '" ' + inputDisabledAttr + ' class="form-control unesi-kolicinu" type="number" min="0" value="0"  >' +
                                '<i class="p-plus plus add-package ' + quanVal + ' "></i>' +
                                '</div>' +
                            '</td>';
                        $('.dugme-za-upit').removeAttr("disabled");


                    } else {
                        if(minPackage > 1) {
                        tdList += '<td class="quantity-buy quan-buy' + key + '">' +
                            '<div class="input-counter">' +
                                '<i class="p-minus minus remove-package ' + quanVal + ' "></i>' +
                                    '<input name="' + value[0].real_id + '" ' + inputDisabledAttr + '  class=" form-control unesi-kolicinu" type="number" min="0" value="0"  >' +
                                '<i class="p-plus plus add-package ' + quanVal + ' "></i>' +
                            '</div>' + 
                            '<span class="tooltip-custom"><i class="p-info"></i> <span class="tooltiptext">Proizvod se poručuje na pakovanje</span></span>' +
                            '</td>';
                        } else {
                            tdList += '<td class="quantity-buy quan-buy' + key + '">' +
                            '<div class="input-counter">' +
                                '<i class="p-minus minus remove-package ' + quanVal + ' "></i>' +
                                    '<input name="' + value[0].real_id + '" ' + inputDisabledAttr + '  class=" form-control unesi-kolicinu" type="number" min="0" value="0"  >' +
                                '<i class="p-plus plus add-package ' + quanVal + ' "></i>' +
                            '</div>' + 
                            '</td>';
                        }
                        $('.dugme-za-upit').removeAttr("disabled");
                        $('[data-toggle="tooltip"]').tooltip();
                    }
                }
                
                $('.tabela-podaci tbody').append('<tr>' + tdList + '</tr>');
                let tableDIV = '<table class="w-100">' +
                    '<tbody class="d-flex flex-row justify-content-between" style="height: 100px;">' +
                    '<tr class="d-flex flex-column justify-content-around">' + zaglavlje_tabele + '</tr><tr class="d-flex flex-column pl-3">' + tdList + '</tr></tbody></table>';


                if (collapse_index != 1) {
                    show = '';
                }

                $('#accordion-product-request').append('<div class="card">' +
                    '<div class="card-header" id="heading' + collapse_index + '">' +
                    '<h5 class="mb-0"><a data-toggle="collapse" data-target="#collapse' + collapse_index + '" aria-expanded="true" aria-controls="collapse' + collapse_index + '">' + sifra_velicine + ' </a></h5>' +
                    '</div>' +
                    '<div id="collapse' + collapse_index + '" class="collapse ' + show + '" aria-labelledby="heading' + collapse_index + '" data-parent="#accordion-product-request">' +
                    '<div class="card-body">' +
                    '<div class="item quantity-mobile">' + value[0].quantity + '</div>' +
                    '<div class="item"><strong>U dolasku: </strong>' + value[0].arrivals + '</div>' +    
                    '<div class="item"><strong>Cena (bez PDV-A): </strong>' + (priceValue == '0' ? posaljiNaUpit : (priceValue + '&nbsp;EUR</div>')) +
                    '<div class="item quantity-buy quan-buy"><strong>Količina: </strong>' +
                        '<div class="input-counter">' +
                            '<i class="p-minus minus add-package ' + quanVal + ' "></i>' +
                            '<input name="' + value[0].real_id + '" ' + inputDisabledAttr + '  class=" form-control unesi-kolicinu" type="number" min="0" value="0">' +
                            '<i class="p-plus plus add-package ' + quanVal + ' "></i>' +
                        '</div>' +
                   ' </div>' + 
                    '</div></div></div>'); 

                collapse_index++;

                let color_table_head = $('.drop-size .show').parent();
                color_table_head.find('.card-header').addClass('main-color-bg');
                // console.log('Oboji',color_table_head.find('.card-header'));
                $('.drop-size .btn-link').on('click', function () {
                    $('.card-header').removeClass('main-color-bg');
                    $(this).parent().toggleClass('main-color-bg');
                });

                // Kolicina 0 Start
                let product_cat = $('.product-cat-info').text();
                let kolicina = parseInt($('.quan' + key).text()) + parseInt($('.arriv' + key).text());
                let quantInput = $('.quan-buy' + key + ' input');

                // set max quantity
                quantInput.attr('max', kolicina);

                if (kolicina == 0 && product_cat != "Kalendari" && product_cat != "Kožna galanterija") {
                    $('.quantity-buy').append('Nije dostupan');
                    $('.unesi-kolicinu').addClass('d-none');
                    $('.input-counter').addClass('d-none');
                }
                // Kolicina 0 End
                if (value[0].quantity_query_on_request == 1 && (product_cat == 'Kalendari' || product_cat == 'Kožna galanterija')) {
                    // console.log(value[0].quantity_query_on_request);
                    // console.log("Koza")
                    $('.table .quantity').empty().append('Na upit');
                    $('.table .arrivals').empty().append('Na upit');
                    $('.card-body .quantity').empty().append('Na upit');
                    $('.card-body .arrivals').empty().append('Na upit');

                    quantInput.attr('max', 999999);
                }

            });
        }


        $('.minus').click(function () {

            const quantity = $(this).parents('tr').find('.quantity').text(); 
            const quantityMobile = $(this).parents('.card-body').find('.item.quantity-mobile').text(); 


            if(!parseInt(quantity) && !parseInt(quantityMobile)) {
                e.preventDefault();
                return;
            } 
            
            // TOTAL PACK
            var $input = $(this).parent().find('input');
            let packageMin = $('#commercial-package').val() ? +$('#commercial-package').val() : 1;
            let totalPackageFiled = $('#total-package');
            let totalPackageFiledMob = $('#total-package-mob');
            let totalPackageSum = +totalPackageFiled.val();
            
            var count = parseInt($input.val()) - packageMin;
            if (count < 0 || count == 0) {
                count = 0;
                $('button.add-to-cart-btn').prop('disabled', true);
                $('button.add-to-cart-btn').css('cursor', 'not-allowed !important');
            } else {
                count = count;
                $('button.add-to-cart-btn').prop('disabled', false);
            }
            $input.val(count);
            $input.change();
            return false;
        });

        $('.plus').click(function (e) {

            const quantity = $(this).parents('tr').find('.quantity').text(); 
            const quantityMobile = $(this).parents('.card-body').find('.item.quantity-mobile').text(); 


            if(!parseInt(quantity) && !parseInt(quantityMobile)) {
                e.preventDefault();
                return;
            } 
            
            // TOTAL PACK
            var $input = $(this).parent().find('input');
            let packageMin = $('#commercial-package').val() ? +$('#commercial-package').val() : 1;
            let reqQuanty = +$(this).val();
            let totalPackageFiled = $(this);
            let totalPackageSum = 0;

            $input.val(parseInt($input.val()) + packageMin);
            $input.change();

            $('button.add-to-cart-btn').prop('disabled', false);
            if (reqQuanty % packageMin !== 0) {
                totalPackageSum = reqQuanty + (packageMin - (reqQuanty % packageMin));
            }else{
                totalPackageSum = reqQuanty;
            }
            
            totalPackageFiled.val(totalPackageSum);
        });

        let timeoutId = 0;
        $(".unesi-kolicinu").on("keypress", function () {
            
            clearTimeout(timeoutId); // doesn't matter if it's 0
            timeoutId = setTimeout(() => {
               
                // TOTAL PACK
                let packageMin = $('#commercial-package').val() ? +$('#commercial-package').val() : 1;
                let reqQuanty = +$(this).val();
                let totalPackageFiled = $(this);
                let totalPackageSum = 0;

                if (reqQuanty % packageMin !== 0) {
                    totalPackageSum = reqQuanty + (packageMin - (reqQuanty % packageMin));
                }else{
                    totalPackageSum = reqQuanty;
                }

                if (reqQuanty == 0) { 
                    $('button.add-to-cart-btn').prop('disabled', true);
                    $('button.add-to-cart-btn').css('cursor', 'not-allowed !important');
                } else {
                    $('button.add-to-cart-btn').prop('disabled', false);
                }

                totalPackageFiled.val(totalPackageSum);
            }, 500);
            // return false;
        }); 

        var quantityZero = $('.unesi-kolicinu').val();

        if (quantityZero == 0) { 
            $('button.add-to-cart-btn').prop('disabled', true);
            $('button.add-to-cart-btn').css('cursor', 'not-allowed !important');
        } else {
            $('button.add-to-cart-btn').prop('disabled', false);
        }

    }

    //Prevent enter for adding products
    $(document).on("keydown", "#add-cart-product, #cartform", function(event) { 
        return event.key != "Enter";
    });

    function mappingGalleryImages() {
        const listGalleryOwl = $.makeArray($('.owl-carousel-two .owl-item'));

        dynamicGalleryItems = listGalleryOwl.map((item, index) => {
            const srcLight = $(item).find('img').attr('src');
            const thumbLight = $(item).find('img').attr('src');

            return {
                src: srcLight,
                thumb: thumbLight,
                // subHtml: item.title,
                index: index
            }
        })  
    }

    // Init data for color
    initDataForColor(); 

    $(".thumbnails").thumbnailsGal();
    $('.image-product').on('load', function() {
        $('.image-product').attr('src');
        $('#ex1').zoom();
    });

});
