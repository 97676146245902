$(document).ready(function () {

    const bsStepperEl = document.querySelector('.bs-stepper');
    const bsStepperHeader = document.querySelector('.bs-stepper-header');
    var urlDomain = window.location.origin;

    const finalConfiguratorItems = [];
    const tiraz_list = $('.tiraz-list');
    const tirazConfig = {
        tirazData: []
    }

    const stepper = new Stepper(bsStepperEl, {
        linear: false
    });

    if (bsStepperEl) {

        const itemChoose = document.querySelectorAll('.configurator-content .item');
        const steps = document.querySelectorAll('.step');



        enableNextSteps();

        itemChoose.forEach((item, key) => {
            item.addEventListener('click', function (e) {
                slideSteps();
                stepper.next();
                enableNextSteps();
            });
        });

        steps.forEach((step, key) => {


            step.addEventListener('click', function (e) {
                const currentIndex = Array.from(bsStepperHeader.children).indexOf(this);

                finalConfiguratorItems.splice(currentIndex);
                renderFinalResult();

                $(this).removeClass('finish');
                enableNextSteps();
            });
        });

    }

    function enableNextSteps() {
        const active = $('.step.active');

        // console.log(active.attr('id'));

        //Send finalConfiguratorItems
        if (active.attr('id') === 'tiraz-nesto') {
            console.log('pre ajax', finalConfiguratorItems);

            const token = $("input[name='_token']").val();
            setTimeout(function () {
                $.ajax({
                    url: urlDomain + "/sr" + "/konfigurator/pricelist",
                    type: "POST",
                    headers: {
                        'X-CSRF-TOKEN': token,
                    },
                    dataType: 'json',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        conf_step_options: finalConfiguratorItems 
                    }),
                    beforeSend: function () {
                        // console.log(finalConfiguratorItems[5]);
                        // console.log('before send', JSON.stringify(finalConfiguratorItems));
                    },
                    success: function (res) {
                        // console.log('success', res);
                        tirazConfig.tirazData = res;
                        tirazConfig.showAllPrinting(res)

                        $(".item-tiraz").on('click', function (e) {
                            e.preventDefault()

                            slideSteps();
                            stepper.next();
                            enableNextSteps();

                            const amount = $(this).data('amount');
                            const price = $(this).data('price');

                            finalConfiguratorItems.push({
                                price,
                                amount
                            });

                            // console.log('ajax final array', finalConfiguratorItems);

                            renderFinalResult();
                        });

                    },
                    error: function (err) {
                        console.log(err);

                    }
                })
            }, 1000)
        }

        $(active).find('button.step-trigger').prop('disabled', false);

        $(active).nextAll('.step').each((index, item) => {
            // console.log(index);
            $(item).removeClass('finish');
            $(item).find('button.step-trigger').prop('disabled', true);
        })

        $(active).prevAll('.step').each((index, item) => {
            // console.log(index);
            $(item).find('button.step-trigger').prop('disabled', false);
            $(item).addClass('finish');
        })
    }

    //Show tiraz
    tirazConfig.showAllPrinting = function (tirazData) {
        tiraz_list.html('');
        if (tirazConfig.tirazData.length > 0) {
            tirazConfig.tirazData.forEach(function (tiraz, index) {

                const {
                    id,
                    amount,
                    price
                } = tiraz;

                // <div class="item item-tiraz" data-price="${price}" data-amount="${amount}">
                //     <i class="p-shopping-cart"></i>
                //     ${amount}
                // </div>

                tiraz_list.append(`
                
                <tr>
                    <td>${amount}</td>
                    <td>${price.toFixed(2)} RSD</td>
                    <td><a href="#" data-price="${price}" data-amount="${amount}" class="btn btn-primary item item-tiraz d-flex align-items-center" data-toggle="tooltip" data-placement="top" title="Izaberi tiraz">Dodajte za upit </a></td>
                </tr> 
                
            `);
            });
        } else {
            tiraz_list.append(`<h5 class="w-100 mt-5 text-center">Prazna lista</h5>`);
        }
    }

    function slideSteps() {
        const bsStepperHeader = document.querySelector('.bs-stepper-header');
        const active = document.querySelector('.step.active');

        bsStepperHeader.scrollLeft += active.offsetWidth;
        // console.log(active);
    }

    //Final resulat render
    function renderFinalResult() {
        $('.steps-results').html('');
        $('.final-price').html('');
        finalConfiguratorItems.forEach((item, index) => {
            const { 
                price
            } = item;
            // console.log('final item',item);  
            $('.final-price').html('');
            $('.steps-results').append(`<p>${item.conf_step?.name ? item.conf_step.name : 'Tiraž'}: <strong>${item.name ? item.name : item.amount}</strong></p>`);
            $('.final-price').append(`Ukupna cena: <strong>${price?.toFixed(2)} RSD</strong>`);
        })
    }

    $(".item-configurator").on('click', function (e) {
        e.preventDefault()
        console.log('click');
        const value = $(this).data('value');


        finalConfiguratorItems.push(value);
        // console.log(finalConfiguratorItems);

        renderFinalResult();
    });



    /*
     *  Configurator form submit
     */

    $('#configurator-form-submit button[type="submit"]').on('click', function (e) {
        e.preventDefault();

        var name = $("input#name").val();
        var phone = $("input#phone").val();
        var email = $("input#email").val();
        var message = $("textarea#message").val();
        var token = $("input[name='_token']").val();
        var ajax = $("input[name='ajax']").val();
        var urlPrefix = $('#configurator-form-submit').data('url');
        var urlDomain = window.location.origin;



        $.ajax({
            url: urlDomain + "/" + urlPrefix + "/konfigurator/submit",
            type: "POST",
            headers: {
                'X-CSRF-TOKEN': token
            },
            data: {
                name,
                phone,
                email,
                message,
                ajax,
                conf_data: finalConfiguratorItems
            },
            success: function (res) {
                console.log(res);
                $('.error-text').html('');
                $('.alert').append(res.successmessage);
                
                setTimeout(function () {
                    $('.alert').addClass('active');
                }, 200);

                setTimeout(function () {
                    $('.alert').removeClass('active');
                }, 1500)
                $('#configurator-form-submit').trigger("reset");

                setTimeout(function() {
                    location.reload();
                }, 2000)

                // $('.bs-stepper-header .step[data-target="#part-1"]').addClass('active');
                // $('.bs-stepper-header .step').removeClass('finish')
                // $('.bs-stepper-content #part-1').addClass('active dstepper-block');
                // $('.bs-stepper-header').children().last().removeClass('active');
                // $('.bs-stepper-content').children().last().removeClass('active dstepper-block');
                // $('.steps-results').html('');


            },
            error: function (err) {
                let {
                    errors
                } = err.responseJSON;
                $('.error-text').html('');
                $('#name-error').append(errors.name);
                $('#email-error').append(errors.email);
                $('#phone-error').append(errors.phone); 
                $('#message-error').append(errors.message);

            },
        });
    });




})